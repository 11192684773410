import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Autocomplete,
  Checkbox,
  Link
} from '@mui/material'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add, AddCircle, Close, KeyboardArrowRight } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { bloodGroupArray } from '../../donors/profile/EditProfile'
import AxiosHelper from '../../../helper/AxiosHelper'
import RHFTextField from '../../shared/RHFTextfield'
import RHFSelect from '../../shared/RHFSelect'
import { RHFDatePicker } from '../../shared/RHFDatePicker'
import FormProvider from '../../shared/FormProvider'
import { bloodComponent } from '../../donor-inventory/data'
import Loader from '../../shared/Loader'
import dayjs from 'dayjs'
import { checkPermission, getTwoInitials } from '../../middleware/auth'
import { inputStyles, inputStylesSm, labelStyles } from '../../certification/EditCertificate'
import TextButton from '../../shared/components/TextButton'
import { phoneRegExp } from '../../abha/components/DonorDetail'
import { radioStyles } from '../payment/Billing'
import { Trans } from 'react-i18next'
import { addNewHospital, debouncing, matchHospitalName } from '../../../helper/CommonAPI'
import ModalCustom, { ModalActions } from '../../shared/ModalCustom'
import { AddHospitalForm } from './AddHospitalForm'
import LoadingButton from '../../shared/LoadingButton'
import { CustomAutocomplete } from '../../shared/custom-autocomplete'
import { useAppContext } from '../../../AppContext'

export const FormDivider = ({ title }) => {
  return (
    <Grid className='Donor-details-sections' item xs={12}>
      <div className='detail-header'>{title ? title : ''}</div>
    </Grid>
  )
}

const initialComp = {
  component: '',
  volume_normal: '',
  unit: '',
  volumes: []
}

const attendeerelation = [
  'Father',
  'Mother',
  'Son',
  'Daughter',
  'Husband',
  'Wife',
  'Brother',
  'Sister',
  'Grandfather',
  'Grandmother',
  'Grandson',
  'Uncle',
  'Aunt',
  'Nephew',
  'Niece',
  'Cousins',
  'Father-in-law',
  'Mother-in-law',
  'Brother-in-law',
  'Sister-in-law',
  'Friend',
  'Other'
]

export const requestType = [
  'General',
  'Thalassemia',
  'Emergency',
  'Hospital Request',
  'Quick Spin',
  'Hemophilia',
  'Sickle Cell',
  'Anemia',
  'Surgery',
  'Dengue',
  'Accident',
  'Cancer Treatment',
  'Childbirth',
  'Transfusion',
  'Organ Transplant',
  'Burn Victims',
  'Rare Blood Type',
  'Chronic Illness',
  'Trauma',
  'Other'
]

const defaultValues = {
  request_order_id: '',
  lastName: '',
  password: '',
  firstName: '',
  request_number: '',
  request_prefix: '',
  component_details: [initialComp],
  is_aliqout: false,
  gender: 'Male',
  state: '',
  district: '',
  hopsital_address: '',
  city: '',
  hospital_mobile: '',
  hospital_id: '',
  hospital_name: '',
  request_date: dayjs(),
  sample_date: dayjs(),
  blood_require_date: dayjs(),
  age_type: 'Y',
  hospital_name: ''
}

export const LetterMessage = 'Must be less than 50 characters'

const InputEndProps = {
  endAdornment: (
    <InputAdornment
      position='end'
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <img alt='' src={'../../../assets/images/inputdormet.svg'} />
    </InputAdornment>
  )
}

export const inputMultiStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },

  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

export function convertVolumes(data) {
  return data.map(item => {
    let info = {
      ...item,
      volume: item.volumes.map(volume => volume.vol)
    }
    delete info.volumes
    return info
  })
}

const AddBloodReqForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)
  const [relation, setRelation] = useState('father')
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const [info, setInfo] = useState(null)
  const [isProceedWithBill, setIsProceedWithBill] = useState(false)
  const [hospitals, setHospitals] = useState([])
  const [hospitalsOptions, setHospitalsOptions] = useState([])
  const [hospitalName, setHospitalName] = useState({ hospital_name: '' })
  const [avl, setAvl] = useState({})
  const [aliqout_status, setAliqout_status] = useState(false)
  const { id } = useParams()
  const [isInprocess, setIsInprocess] = useState(false)
  const [isRequestIdAvailable, setIsRequestIdAvailable] = useState(false)
  const [focus, setFocus] = React.useState(false)
  const [regesteredId, setRegisteredId] = useState([])
  const [showModal, setShowModal] = useState(false)
  const { bloodGroups: bloodGroupArray } = useAppContext()

  const handleSelect = value => {
    setValue('hospital_name', value?.hospital_name)
    setValue('hopsital_address', value?.hospital_address)
    setValue('state', value?.state)
    setValue('district', value?.district)
    setValue('city', value?.city)
    setValue('hospital_mobile', value?.contact_number)
    console.log('Selected:', value)
  }

  const history = useHistory()
  const aliqoutMethodValidation = aliqout_status ? yup.string().required('Aliqout method is required') : yup.string()
  const schema = yup.object().shape({
    request_date: yup.string().required('Request Date is required'),
    request_order_id: yup.string(),
    request_number: yup.string().required('Request id is required'),
    patient_id: yup.string().max(50, LetterMessage),
    patient_name: yup.string().required('Patient name is required').max(50, LetterMessage),
    father_name: yup.string().max(50, LetterMessage),
    age: yup
      .string()
      .required('Age is required')
      .max(3, 'Enter Correct Age')
      .test('valid-age', 'Age must be valid based on age type', function (value) {
        const { age_type } = this.parent // Access the sibling field
        const ageValue = parseInt(value) // Parse the age value

        // Validation for age type "D" (days)
        if (age_type === 'D' && ageValue < 90) {
          return this.createError({ message: 'Age must be at least 90 days' })
        }

        // Validation for age type "M" (months)
        if (age_type === 'M' && ageValue <= 3) {
          return this.createError({ message: 'Age must be greater than 3 months' })
        }

        return true // Valid otherwise
      }),
    gender: yup.string().required('Gender is required'),
    attendee_name: yup.string().required('Attendee name is required').max(50, LetterMessage),
    attendee_relation: yup.string().required('Attendee relation is required').max(50, LetterMessage),
    attendee_mobile: yup
      .string()
      .required('Mobile number is required')
      .matches(phoneRegExp, 'mobile number is not valid'),
    refrence: yup.string().max(50, LetterMessage),
    hospital_name: yup.string().required('Hospital name is required'),
    //hospital_id: yup.string().required('Please select a hospital name'),
    hopsital_address: yup.string().required('Hospital address is required').max(150, LetterMessage),
    city: yup.string().required('City is required').max(50, LetterMessage),
    district: yup.string().required('District is required'),
    state: yup.string().required('State is required'),
    doctor_name: yup.string().required('Doctor name is required').max(50, LetterMessage),
    clinical_history: yup.string(),
    blood_group: yup.string(),
    transfusion_reason: yup.string().max(50, LetterMessage),
    transfusion_history: yup.string(),
    sample_id: yup.string(),
    blood_require_date: yup.string().required('Sample Date is required'),
    request_type: yup.string().required('Reason Of Request is required'),
    sample_date: yup.string().required('Recieved Sample Date is required'),
    diagnosis: yup.string().max(50, LetterMessage),
    relation: yup.string(),
    is_aliqout: yup.bool(),
    aliqout_method: aliqoutMethodValidation,
    age_type: yup.string(),
    component_details: yup.array().of(
      yup.object().shape({
        component: yup.string().required('Component is required'),
        unit: yup
          .string()
          .required('Unit is required')
          .max(50, LetterMessage)
          .test('is-greater-than-0', 'Unit must be greater than 0', value => parseInt(value) > 0)
          .test('is-less-than-10', 'Unit must be less than 6', value => parseInt(value) < 10),
        // volume_normal: yup.string(),

        ...(aliqout_status
          ? {
              volumes: yup.array().of(
                yup.object().shape({
                  vol: yup.string().required('Volume is required').max(3, LetterMessage)
                })
              )
            }
          : {
              volume_normal: yup.string()
            })
      })
    ),
    thalassemia_id: yup.string(),
  })

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** Hook
  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors }
  } = methods

  const getStates = () => {
    AxiosHelper.get('address/state')
      .then(response => {
        setStates(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDistict = val => {
    AxiosHelper.get('address/district/' + val)
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const getHospital = async val => {
    await AxiosHelper.get(`hospital?search=${val || ''}`)
      .then(response => {
        let hoslist = response?.data?.data
        // let list = [...hoslist, { id: 'Others', hospital_name: 'Other' }]
        setHospitals(hoslist)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const getPerfix = () => {
    const bodydata = {
      request_type: 'PT',
      request_date: dayjs(watch('request_date')).format('YYYY-MM-DD')
    }
    AxiosHelper.post('request/get-request_number', bodydata)
      .then(response => {
        let numbers = response?.data?.data
        if (numbers?.request_number) {
          setValue('request_number', numbers?.request_number)
        }
        if (numbers?.request_prefix) {
          setValue('request_prefix', numbers?.request_prefix)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const checkRequestId = () => {
    const reqNumber = watch('request_prefix') + '-' + watch('request_number')
    if (info?.request_id === reqNumber) {
      setIsRequestIdAvailable(false)
      return
    }
    AxiosHelper.post('request/check-request_number', { request_type: 'PT', request_number: reqNumber })
      .then(response => {
        console.log(response?.data?.data)
        setIsRequestIdAvailable(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const calculateVolume = compSubBags => {
    console.log(compSubBags)
    let totalVolume = 0
    compSubBags.forEach((ele, i) => {
      totalVolume = totalVolume + parseInt(ele?.vol)
    })

    return totalVolume
  }

  const getAvailability = (bodydata, index) => {
    AxiosHelper.post('request/check-component', bodydata)
      .then(response => {
        setAvl({ ...avl, [index]: response?.data?.data })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const checkBagAvalability = index => {
    if (watch(`component_details.${index}.component`) !== 'Single Donor Platelet') {
      if (watch(`component_details.${index}.unit`)?.length > 0 && watch(`component_details.${index}.unit`) > 0) {
        if (watch('is_aliqout')) {
          const bodydata = {
            type: getValues('blood_group'),
            component: getValues(`component_details.${index}.component`),
            unit: getValues(`component_details.${index}.unit`),
            is_aliqout: true,
            volume: calculateVolume(getValues(`component_details.${index}.volumes`))
          }
          if (bodydata?.volume > 0) {
            getAvailability(bodydata, index)
          }
        } else {
          const bodydata = {
            type: getValues('blood_group'),
            component: getValues(`component_details.${index}.component`),
            unit: getValues(`component_details.${index}.unit`)
          }
          getAvailability(bodydata, index)
        }
      } else {
        setAvl({ ...avl, [index]: '' })
      }
    }
  }

  useEffect(() => {
    setHospitalsOptions(hospitals)
  }, [hospitals])

  useEffect(() => {
    getStates()
    // getHospital()
    handleInputChange()
  }, [])

  useEffect(() => {
    if (watch('state')) {
      getDistict(watch('state'))
    }
  }, [watch('state'), states])

  const getInfo = () => {
    setPageLoading(true)
    AxiosHelper.get(`request/${id}`)
      .then(res => {
        setInfo(res?.data?.data)
        setPageLoading(false)
        let dataa = res?.data?.data
        const reqId = parseRequestId(dataa?.request_id)
        // Check for volume in `component_details` and handle aliquot
        let componentDetails = dataa?.component_details ? JSON.parse(dataa.component_details) : [initialComp]

        // Map through the parsed `component_details`
        componentDetails = componentDetails.map(component => {
          if (Array.isArray(component.volume)) {
            // If `volume` is an array (aliquot case), set volumes
            return {
              ...component,
              volumes: component.volume.map(v => ({ vol: v })) // Convert to expected format
            }
          } else {
            // Normal case, set `volume_normal`
            return {
              ...component,
              volume_normal: component.volume_normal || ''
            }
          }
        })
        if (dataa?.BloodRequestLogsEntities && dataa?.BloodRequestLogsEntities?.length > 0) {
          setIsInprocess(true)
        } else {
          setIsInprocess(false)
        }
        setRelation(dataa?.relation || 'father')
        setHospitalName({ hospital_name: dataa?.hospital_name || '' })
        reset({
          request_number: reqId?.request_id ? reqId?.request_id : '',
          request_prefix: reqId?.prefix ? reqId?.prefix : '',
          request_date: dataa.request_date || '',
          request_order_id: dataa.id || '',
          patient_id: dataa.patient_id || '',
          patient_name: dataa.patient_name || '',
          father_name: dataa.father_name || '',
          relation: dataa.relation || '',
          age: dataa.age || '',
          gender: dataa.gender || 'Male',
          attendee_name: dataa.attendee_name || '',
          attendee_mobile: dataa.attendee_mobile || '',
          attendee_relation: dataa.attendee_relation || '',
          refrence: dataa.refrence || '',
          hospital_name: dataa.hospital_name || '',
          hopsital_address: dataa.hopsital_address || '',
          state: dataa.state || '',
          district: dataa.district || '',
          city: dataa.city || '',
          doctor_name: dataa.doctor_name || '',
          hospital_mobile: dataa.hospital_mobile || '',
          clinical_history: dataa.clinical_history || '',
          diagnosis: dataa.diagnosis || '',
          blood_group: dataa.blood_group || '',
          transfusion_reason: dataa.transfusion_reason || '',
          transfusion_history: dataa.transfusion_history || '',
          component_details: componentDetails,
          sample_date: dataa.sample_date ? dayjs(dataa.sample_date) : '',
          sample_id: dataa.sample_id || '',
          request_type: dataa.request_type || 'Urgent',
          blood_require_date: dataa.blood_require_date ? dayjs(dataa.blood_require_date) : '',
          is_aliqout: dataa.is_aliqout || false,
          aliqout_method: dataa?.aliqout_method || false,
          ward_number: dataa?.ward_number || '',
          bed_number: dataa?.bed_number || '',
          age_type: dataa?.age_type || 'Y'
        })
      })
      .catch(error => {
        console.log('error', error)
        setPageLoading(false)
      })
  }

  const getRequestIfExist = async url => {
    await AxiosHelper.get(url)
      .then(response => {
        console.log(response?.data?.data)
        if (response?.data?.data) {
          setRegisteredId(response?.data?.data)
        } else {
          setRegisteredId([])
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleId = e => {
    setValue('sample_id', e.target.value)
    setValue('patient_id', e.target.value)
    if (e.target.value) {
      getRequestIfExist(`request/check-existing?patient_id=${e.target.value}`)
    } else {
      setRegisteredId([])
    }
  }

  const setDetailPageData = dataa => {
    const hospitaId = matchHospitalName(dataa.hospital_name, hospitals)
    console.log(hospitaId)

    setRelation(dataa?.relation || 'father')
    setHospitalName({ hospital_name: dataa?.hospital_name || '' })
    const request_prefix = watch('request_prefix')
    const request_num = watch('request_number')
    reset({
      request_number: request_num || '',
      request_prefix: request_prefix || '',
      patient_name: dataa.patient_name || '',
      father_name: dataa.father_name || '',
      relation: dataa.relation || '',
      age: dataa.age || '',
      gender: dataa.gender || 'Male',
      attendee_name: dataa.attendee_name || '',
      attendee_mobile: dataa.attendee_mobile || '',
      attendee_relation: dataa.attendee_relation || '',
      refrence: dataa.refrence || '',
      hospital_name: hospitaId ? dataa.hospital_name : '',
      hopsital_address: hospitaId ? dataa.hopsital_address : '',
      state: dataa.state || '',
      district: dataa.district || '',
      city: dataa.city || '',
      doctor_name: dataa.doctor_name || '',
      hospital_mobile: hospitaId ? dataa.hospital_mobile : '',
      clinical_history: dataa.clinical_history || '',
      diagnosis: dataa.diagnosis || '',
      blood_group: dataa.blood_group || '',
      transfusion_reason: dataa.transfusion_reason || '',
      transfusion_history: dataa.transfusion_history || '',
      sample_date:  dayjs(),
      sample_id: dataa.sample_id || '',
      patient_id: dataa.sample_id || '',
      request_type: dataa.request_type || 'Urgent',
      blood_require_date:  dayjs(),
      ward_number: dataa?.ward_number || '',
      bed_number: dataa?.bed_number || '',
      component_details: [initialComp],
      hospital_id: hospitaId || '',
      age_type: dataa?.age_type || 'Y',
      is_aliqout:false
    })
  }

  function parseRequestId(requestId) {
    let prefix = ''
    let request_id = ''

    try {
      // Check if requestId is a valid string
      if (typeof requestId !== 'string') {
        throw new Error('Input must be a string')
      }

      // If requestId is empty, return default values
      if (requestId.trim() === '') {
        return { prefix, request_id }
      }

      // Check if the string contains a hyphen and split accordingly
      if (requestId.includes('-')) {
        ;[prefix, request_id] = requestId.split('-')

        // Ensure both parts are valid strings
        if (typeof prefix !== 'string' || typeof request_id !== 'string') {
          throw new Error('Invalid split result')
        }
      } else {
        request_id = requestId // No hyphen, so the entire string is the request_id
      }

      return { prefix, request_id }
    } catch (error) {
      console.error('Error:', error.message)
      return { prefix: '', request_id: '' } // Return empty values on error
    }
  }

  useEffect(() => {
    if (id) {
      getInfo()
    } else {
      getPerfix()
    }
  }, [id])

  useEffect(() => {
    if (id) {
      const a = getValues('hospital_name')
      if (a == undefined) {
        setValue('hospital_name', info?.hospital_name)
      }
    }
  }, [info, watch['hospital_name']])

  useEffect(() => {
    setAliqout_status(watch('is_aliqout'))
  }, [watch('is_aliqout')])

  const onSubmit = data => {
    if (isRequestIdAvailable) {
      return
    }
    // for (const key in avl) {
    //   if (avl.hasOwnProperty(key) && avl[key] === false) {
    //     toast.error(`Requested component is out of stock`)
    //     return
    //   }
    // }

    if (data?.is_aliqout) {
      let details = convertVolumes(data?.component_details)
      let bodyData = {
        ...data,
        cross_match_for: 1,
        relation: data?.father_name ? relation : '',
        component_details: details,
        request_id:
          data?.request_prefix && data?.request_number
            ? `${data?.request_prefix + '-' + data?.request_number}`
            : data?.request_number
      }
      handleRequest(bodyData)
    } else {
      let details = convertNormalVolumes(data?.component_details)
      let bodyData = {
        ...data,
        cross_match_for: 1,
        relation: relation,
        relation: data?.father_name ? relation : '',
        component_details: details,
        request_id:
          data?.request_prefix && data?.request_number
            ? `${data?.request_prefix + '-' + data?.request_number}`
            : data?.request_number
      }
      handleRequest(bodyData)
    }
  }

  function convertNormalVolumes(data) {
    return data.map(item => {
      let info = {
        ...item,
        volume: item.volume_normal
      }
      delete info.volume_normal
      delete info.volumes
      return info
    })
  }

  const handleRequest = bodyData => {
    setLoading(true)
    if (id) {
      AxiosHelper.put(`request/${id}`, bodyData)
        .then(response => {
          toast.success('Blood Request updated successfully!')
          setLoading(false)
          history.push(`/blood-requisition/view/${id}`)
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
          toast.error(error || 'Something went wrong our side!')
        })
    } else {
      AxiosHelper.post('request', bodyData)
        .then(response => {
          toast.success('Blood Request added successfully!')
          setLoading(false)
          if (isProceedWithBill && response?.data?.data?.id) {
            history.push(`/billing/payment/${response?.data?.data?.id}`)
          } else {
            history.push('/blood-requisition')
          }
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
          toast.error(error || 'Something went wrong our side!')
        })
    }
  }

  const addComponent = () => {
    if (!isInprocess) {
      const component_details = [...watch('component_details')]
      component_details.push(initialComp)
      setValue('component_details', component_details)
    }
  }

  const removeItem = index => {
    const component_details = [...watch('component_details')]
    component_details.splice(index, 1)
    if (component_details?.length === 0) {
      component_details.push({
        street_address: '',
        suburb: '',
        state: '',
        postal_code: ''
      })
    }

    setValue('component_details', component_details)
  }

  const handleHospitalReset = obj => {
    setValue('hospital_name', obj?.hospital_name)
    setValue('state', obj?.state)
    setValue('hopsital_address', obj?.hospital_address)
    setValue('district', obj?.district)
    setValue('city', obj?.city)
    setValue('hospital_mobile', obj?.contact_number)
  }

  const handleSelectComponents = e => {
    let arr = getValues('component_details')
    let comp = []
    arr.forEach((el, i) => {
      comp.push(el.component)
    })
    if (comp.includes(e.target.value)) {
      setValue(e.target.name, '')
      setError(e.target.name, {
        type: 'manual',
        message: 'Component already selected'
      })
    } else {
      setValue(e.target.name, e.target.value)
      clearErrors()
    }
  }

  const handleVolume = (val, i) => {
    let arr = []
    while (val > 0 && val < 6) {
      arr.push({ vol: '' })
      val--
    }
    setValue(`component_details.${i}.volumes`, arr)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSubmitModal = async formDataFromModal => {
    try {
      // alert('eee')
      // const formData = submitFormData
      const formData = {
        hospital_name: formDataFromModal.hospital_name,
        hospital_address: formDataFromModal.hospital_address,
        state: formDataFromModal?.state,
        district: formDataFromModal?.district,
        city: formDataFromModal?.city,
        contact_number: formDataFromModal.contact_number || watch('hospital_mobile')
      }
      setLoading(true)
      const { data } = await addNewHospital(formData)
      if (!data?.data) {
        throw new Error('Something went wrong while adding the hospital!')
      }

      await handleInputChange()

      console.log(`submiting form -> `, data, formData)
      handleSelect(data?.data)
      // setValue('hospital_name', data?.data?.hospital_name)
      // setValue('hopsital_address', data?.data?.hospital_address)
      // setValue('state', data?.data?.state)
      // setValue('district', data?.data?.district)
      // setValue('city', data?.data?.city)
      // setValue('hospital_mobile', data?.data?.contact_number)
      // setHospitalName({ hospital_name: data?.data?.hospital_name })
      setHospitals(prev => [...prev, data.data])
      console.log(`api data modal ->`, data?.data)
      // await getHospital()
    } catch (error) {
      toast.error(error || 'Something went wrong while adding the hospital!')
    } finally {
      setShowModal(false)
      setLoading(false)
    }
  }

  const handleOnChangeAutocomplete = (event, obj) => {
    if (obj.id) {
      setHospitalName(obj)
      clearErrors('hospital_id')
      setValue('hospital_id', obj.id)
      handleHospitalReset(obj)
   //   console.log(`hospital_name id -> `, obj)
      setValue("hospital_name",obj.hospital_name)
    }
  }

  const updateAll = useCallback(
    debouncing(e => {
      // getHospital(searchValue)
      handleInputChange(e.target.value)
    }, 200),
    []
  )

  const [hospitalOptions, setHospitalOptions] = useState([])

  const handleInputChange = async value => {
    let url = 'hospital'
    setAutoCompleteLoading(true)
    if (value) {
      url = `hospital?search=${value}`
    }
    try {
      const response = await AxiosHelper.get(url)
      setHospitalOptions(response?.data?.data)
      setAutoCompleteLoading(false)
    } catch (error) {
      console.error('Error fetching hospital data:', error)
      setAutoCompleteLoading(false)
    }
  }

  useEffect(() => {
    if (watch('hospital_name')) {
      handleInputChange(watch('hospital_name'))
    }
  }, [watch('hospital_name')])
  
  if (pageLoading) {
    return <Loader isLoading={true} />
  }



  console.log(`watch -> `, watch('hospital_name'))

  return (
    <div>
      <AddHospitalForm
        showModal={showModal}
        states={states}
        districts={districts}
        getDistict={getDistict}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleSubmitParent={handleSubmitModal}
        hospitalNameQuery={watch('hospital_name')}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <FormDivider title='Details' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Hospital Patient Id
            </FormLabel>
            {/* <RHFTextField name='patient_id' size='small' fullWidth sx={inputStyles} onChange={handleId} /> */}
            <RHFTextField
              sx={inputStyles}
              fullWidth
              size='small'
              onFocus={() => setFocus(true)}
              onBlur={() => setTimeout(() => setFocus(false), 200)}
              // onKeyDown={e => {
              //   if (e.key === 'Tab') {
              //     setFocus(false)
              //   }
              // }}
              placeholder='Hospital Patient Id'
              name='patient_id'
              onChange={handleId}
              value={watch('patient_id')}
            />

            {focus ? (
              <div style={{ position: 'absolute', maxWidth: '300px' }} className='searchCustomm '>
                {regesteredId?.length > 0 ? (
                  regesteredId?.map((item, i) => (
                    <div
                      onClick={() => {
                        console.log(item)
                        setDetailPageData(item)
                        setFocus(false)
                      }}
                      key={i}
                      className='nav-item cursor-pointer my-2 '
                    >
                      <div className='nav-profile-textt'>
                        <h1>{getTwoInitials(item?.patient_name)}</h1>
                      </div>
                      <div className='nav-profile-text'>
                        <span className='font-weight-bold mb-2'>
                          <Trans>{item?.patient_name}</Trans>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Request found with this Hospital Patient Id</p>
                )}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Request Id <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              name='request_number'
              sx={inputStyles}
              onChange={e => {
                setValue('request_number', e.target.value)
                if (e.target.value) {
                  checkRequestId()
                }
              }}
              InputProps={{
                startAdornment: (
                  <TextField
                    name='request_prefix'
                    size='small'
                    value={watch('request_prefix') || 'Prefix N/A' + ' -'}
                    variant='standard'
                    sx={{ maxWidth: '80px', margin: '0px 8px 0px 0px', position: 'relative', top: '2px' }}
                    disabled
                    InputProps={{
                      disableUnderline: true // Disable the underline
                    }}
                  />
                )
              }}
            />
            {isRequestIdAvailable && watch('request_number').length > 0 ? (
              <FormHelperText sx={{ color: 'error.main' }}>Request ID not available</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Request Date
              <span className='text-danger'>*</span>
            </FormLabel>
            <RHFDatePicker
              name='request_date'
              disableFuture
              sx={inputStyles}
              onChange={date => {
                setValue('request_date', dayjs(date).format('YYYY-MM-DD'))
                getPerfix()
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Reason Of Request<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect
              size='small'
              name='request_type'
              fullWidth
              sx={inputStyles}
              //  value={"attendee_relation"}
              //   onChange={(e)=>{setValue(e.target.value)}}
            >
              {requestType.length > 0 ? (
                requestType?.map((rel, i) => (
                  <MenuItem key={i} value={rel}>
                    {rel}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No Relation Found</MenuItem>
              )}
            </RHFSelect>
          </Grid>

          {
            watch('request_type') === 'Thalassemia' ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormLabel sx={labelStyles}>
                  Thalassemia Id
                </FormLabel>
                <RHFTextField size='small' name='thalassemia_id' fullWidth sx={inputStyles} />
              </Grid>
                ) : null
          }

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Patient Name <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' name='patient_name' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Father/Husband Name</FormLabel>
            <RHFTextField
              size='small'
              name='father_name'
              fullWidth
              sx={inputStyles}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <Select
                      size='small'
                      variant='standard'
                      disableUnderline
                      value={relation}
                      onChange={e => setRelation(e.target.value)}
                    >
                      <MenuItem value='father'>S/O</MenuItem>
                      <MenuItem value='husbasnd'>W/O</MenuItem>
                      <MenuItem value='daughter'>D/O</MenuItem>
                    </Select>
                  </Box>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Patient Age<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              name='age'
              type='number'
              size='small'
              fullWidth
              sx={inputStyles}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <RHFSelect size='small' variant='standard' disableUnderline name='age_type'>
                      <MenuItem value='Y'>Year</MenuItem>
                      <MenuItem value='M'>Month</MenuItem>
                      <MenuItem value='D'>Day</MenuItem>
                    </RHFSelect>
                  </Box>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Gender<span className='text-danger'>*</span>
            </FormLabel>
            <ul style={{ marginTop: '0.45rem' }} className='donate-now flex'>
              <li>
                <input
                  type='radio'
                  id='a25'
                  value='Male'
                  name='gender'
                  checked={watch('gender') === 'Male'}
                  onChange={() => setValue('gender', 'Male')}
                />
                <label htmlFor='a25'>Male</label>
              </li>
              <li>
                <input
                  type='radio'
                  id='a50'
                  value='Female'
                  name='gender'
                  checked={watch('gender') === 'Female'}
                  onChange={() => setValue('gender', 'Female')}
                />
                <label htmlFor='a50'>Female</label>
              </li>
              <li>
                <input
                  type='radio'
                  id='a75'
                  value='Other'
                  name='gender'
                  checked={watch('gender') === 'Other'}
                  onChange={() => setValue('gender', 'Other')}
                />
                <label htmlFor='a75'>Other</label>
              </li>
            </ul>
            {errors.gender ? (
              <FormHelperText sx={{ color: 'error.main' }}>{errors.gender?.message}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Attendee Name <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' name='attendee_name' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Attendee Relation <span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect
              size='small'
              name='attendee_relation'
              fullWidth
              sx={inputStyles}
              //  value={"attendee_relation"}
              //   onChange={(e)=>{setValue(e.target.value)}}
            >
              {attendeerelation.length > 0 ? (
                attendeerelation?.map((rel, i) => (
                  <MenuItem key={i} value={rel}>
                    {rel}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No Relation Found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Ward</FormLabel>
            <RHFTextField size='small' name='ward_number' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Bed Number</FormLabel>
            <RHFTextField size='small' name='bed_number' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Attendee Mobile Number<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              name='attendee_mobile'
              type='number'
              size='small'
              fullWidth
              sx={inputStyles}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <Select size='small' variant='standard' disableUnderline value='+91'>
                      <MenuItem value='+91'>+91</MenuItem>
                    </Select>
                  </Box>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Reference/Care Of</FormLabel>
            <RHFTextField size='small' name='refrence' fullWidth sx={inputStyles} />
          </Grid>
          <FormDivider title='Hospital Information' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Hospital Name<span className='text-danger'>*</span>
            </FormLabel>
            <Autocomplete
              loading={autoCompleteLoading}
              autoHighlight
              noOptionsText={
                <div
                  className='custom-autocomplete__item custom-autocomplete__add-item'
                  onClick={() => setShowModal(true)}
                >
                  Add New Hospital
                </div>
              }
              options={hospitalOptions}
              getOptionLabel={option => option.hospital_name}
              // onInputChange={updateAll}
              name={'hospital_name'}
              value={
                watch('hospital_name')
                  ? (hospitalOptions.find(option => {
                      return watch('hospital_name') === option.hospital_name
                    }) ?? null)
                  : null
              }
              onChange={(event, value) => {
                if (value) {
                  handleSelect(value) // Trigger custom logic on selection
                }
              }}
              filterOptions={options => options} // Disable client-side filtering
              renderInput={params => (
                <TextField {...params} name='hospital_name' sx={inputStyles} onChange={updateAll} />
              )}
            />
            {errors.hospital_id ? (
              <FormHelperText sx={{ color: 'error.main' }}>
                {hospitalName?.hospital_name
                  ? 'Please select a hospital or add a new one if it’s not listed.'
                  : errors.hospital_id?.message}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Hospital Address<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' name='hopsital_address' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              State<span className='text-danger'>*</span>
            </FormLabel>
            {/* <RHFTextField
                      size="small"
                      name="state"
                      fullWidth
                      sx={inputStyles}
                    />  */}
            <RHFSelect size='small' name='state' fullWidth sx={inputStyles}>
              {states?.length > 0 ? (
                states?.map((stat, i) => (
                  <MenuItem key={i} value={stat?.name}>
                    {stat?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No States found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              District<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect size='small' name='district' fullWidth sx={inputStyles}>
              {districts?.length > 0 ? (
                districts?.map((stat, i) => (
                  <MenuItem key={i} value={stat?.name}>
                    {stat?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No District found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              City/Village<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' type='text' fullWidth name='city' sx={inputStyles} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormLabel sx={labelStyles}>
                      Hospital Name<span className="text-danger">*</span>
                    </FormLabel>
                    <RHFTextField
                      name="hospital_name"
                      size="small"
                      fullWidth
                      sx={inputStyles}
                    />
                  </Grid> */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Hospital Contact Number
              {/* <span className="text-danger">*</span> */}
            </FormLabel>
            <RHFTextField
              size='small'
              fullWidth
              type='number'
              name='hospital_mobile'
              sx={inputStyles}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <Select size='small' variant='standard' disableUnderline value='+91'>
                      <MenuItem value='+91'>+91</MenuItem>
                    </Select>
                  </Box>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Doctor Name<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              size='small'
              fullWidth
              name='doctor_name'
              sx={inputStyles}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <Select size='small' variant='standard' disableUnderline value='Dr.'>
                      <MenuItem value='Dr.'>Dr.</MenuItem>
                    </Select>
                  </Box>
                )
              }}
            />
          </Grid>
          <FormDivider title='Diagnostic And Clinical Information' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Reason for Transfusion</FormLabel>
            <RHFTextField name='transfusion_reason' size='small' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Diagnosis</FormLabel>
            <RHFTextField size='small' fullWidth name='diagnosis' sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Does Patient have transfusion history? </FormLabel>

            <RHFSelect size='small' fullWidth sx={inputStyles} name='transfusion_history'>
              <MenuItem value='Yes'>Yes</MenuItem>
              <MenuItem value='No'>No</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item xs={12}>
            <FormLabel sx={labelStyles}>Clinical History</FormLabel>
            <RHFTextField
              size='small'
              multiline
              minRows={2}
              maxRows={5}
              fullWidth
              sx={inputMultiStyles}
              name='clinical_history'
            />
          </Grid>
          <FormDivider title='Blood Component Request' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Blood Group of Patient
            </FormLabel>
            <RHFSelect
              size='small'
              name='blood_group'
              fullWidth
              sx={inputStyles}
              onChange={e => {
                setValue('component_details', [initialComp])
                setValue('blood_group', e.target.value)
                setAvl({})
              }}
              disabled={isInprocess}
            >
              <MenuItem value=''>
                No Blood Group
              </MenuItem>
              {bloodGroupArray?.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </RHFSelect>
            {watch('blood_group')?.length > 0 ? null : (
              <FormHelperText>Please select blood group before select the component</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <Checkbox
                sx={{ ...radioStyles, marginTop: '4px' }}
                name='is_aliqout'
                checked={watch('is_aliqout')}
                onChange={e => {
                  setValue(e.target.name, e.target.checked)
                  setValue('component_details', [initialComp])
                  setValue('aliqout_method', '')
                }}
                disabled={isInprocess}
              />
              <div>
                <FormLabel sx={labelStyles}>
                  <div className='mt-2 label-txt'>ALIQUOT Blood Bag </div>
                </FormLabel>
                <FormHelperText>Split the blood bag into several smaller containers</FormHelperText>
              </div>
            </Box>
          </Grid>
          {watch('is_aliqout') && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                Aliqout Method
                <span className='text-danger'>*</span>
              </FormLabel>
              <RHFSelect size='small' name='aliqout_method' fullWidth sx={inputStyles} disabled={isInprocess}>
                <MenuItem value={2}> Close Method </MenuItem>
                <MenuItem value={1}> Open Method</MenuItem>
              </RHFSelect>
              <FormHelperText>
                Using the open method to aliquot changes the expiry date to the next day. Using the close method keeps
                the expiry date the same.
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            {watch('component_details')?.map((field, index) => (
              <Stack key={field?.id}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display='grid'
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(5, 1fr)'
                  }}
                  sx={{
                    marginTop: 2
                    //alignItems: 'end',
                  }}
                >
                  <Box>
                    <FormLabel sx={labelStyles}>
                      Component Name
                      <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFSelect
                      name={`component_details.${index}.component`}
                      disabled={isInprocess}
                      size='small'
                      fullWidth
                      sx={inputStyles}
                      onChange={e => {
                        handleSelectComponents(e)
                        checkBagAvalability(index)
                      }}
                    >
                      {bloodComponent?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 'auto'
                    }}
                  >
                    <FormLabel sx={labelStyles}>
                      {watch('is_aliqout') ? 'Number of splits' : 'Unit'} <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                      size='small'
                      type='number'
                      disabled={isInprocess}
                      name={`component_details.${index}.unit`}
                      fullWidth
                      onChange={e => {
                        setValue(`component_details.${index}.unit`, e.target.value)
                        handleVolume(e.target.value, index)
                        checkBagAvalability(index)
                      }}
                      sx={inputStyles}
                    />
                  </Box>
                  {watch('is_aliqout') === false && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 'auto'
                      }}
                    >
                      <FormLabel sx={labelStyles}>Volume (ML)</FormLabel>
                      <RHFTextField
                        size='small'
                        name={`component_details.${index}.volume_normal`}
                        fullWidth
                        type='number'
                        sx={inputStyles}
                        disabled={isInprocess}
                      />
                    </Box>
                  )}

                  {watch('is_aliqout') &&
                    watch(`component_details.${index}.volumes`) &&
                    watch(`component_details.${index}.volumes`)?.map((field2, index2) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 'auto'
                        }}
                      >
                        <FormLabel sx={labelStyles}>
                          Aliqout-{String.fromCharCode(64 + index2 + 1)} volume (ML)
                        </FormLabel>
                        <RHFTextField
                          size='small'
                          name={`component_details.${index}.volumes.${index2}.vol`}
                          type='number'
                          sx={inputStyles}
                          disabled={isInprocess}
                          onChange={e => {
                            setValue(`component_details.${index}.volumes.${index2}.vol`, e.target.value)
                            checkBagAvalability(index)
                          }}
                        />
                      </Box>
                    ))}

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        xs: 'center',
                        md: 'flex-start'
                      },
                      alignItems: 'center'
                    }}
                  >
                    {avl[index] === true ? 'Available' : <>{avl[index] === false ? 'Not Available' : ''}</>}

                    <IconButton
                      color='error'
                      sx={{
                        display: watch('component_details')?.length > 1 ? 'flex' : 'none',
                        width: 'fit-content',
                        borderRadius: '8px'
                      }}
                      onClick={() => removeItem(index)}
                      disabled={isInprocess}
                    >
                      <Close icon='material-symbols:close' />
                    </IconButton>
                  </Box>
                </Box>
              </Stack>
            ))}
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <TextButton onClick={addComponent}>
              <Add /> Add More
            </TextButton>
            {/* <Button
                startIcon={<Add />}
                color="success"
                variant="text"
              >
              </Button> */}
          </Grid>
          <FormDivider title='Blood Sample' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Date of Sample Recieved
              <span className='text-danger'>*</span>
            </FormLabel>
            <RHFDatePicker name='sample_date' disableFuture sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Sample Id
            </FormLabel>
            <RHFTextField size='small' name='sample_id' fullWidth sx={inputStyles} disabled />
          </Grid>{' '}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Blood Required Date<span className='text-danger'>*</span>
            </FormLabel>

            <RHFDatePicker name='blood_require_date' sx={inputStyles} />
          </Grid>
          <Grid item xs={12} display={'flex'} gap={'10px'} sx={{ paddingBottom: '24px' }}>
            {id ? (
              <button
                type='button'
                className='btn backBtn '
                onClick={() => {
                  history.push(`/blood-requisition/view/${id}`)
                }}
              >
                Cancel
              </button>
            ) : (
              <button
                type='button'
                className='btn backBtn '
                onClick={() => {
                  history.push(`/blood-requisition`)
                }}
              >
                Cancel
              </button>
            )}

            {checkPermission('ADD_REQUEST') || checkPermission('UPDATE_REQUEST') ? (
              <button type='submit' disabled={isLoading} className='btn gradient-btn'>
                {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
              </button>
            ) : null}
            {!id && checkPermission('CREATE_BILLING') === true ? (
              <button
                type='submit'
                disabled={isLoading}
                className='btn gradient-btn'
                onClick={() => {
                  setIsProceedWithBill(true)
                }}
              >
                {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save & proceed with billing'}
              </button>
            ) : null}
          </Grid>
        </Grid>
      </FormProvider>
    </div>
  )
}

export default AddBloodReqForm
